<template>
	<div class="page-container">
		<Head />
		<div class="page-banner" :style="{backgroundImage:'url('+banner+')'}">
			<div class="container banner-align-right">
				<div class="banner-text wow fadeInDown">
					<div class="ch">产品服务</div>
					<div class="en">PRODUCT SERVICE</div>
					<img src="../assets/image/down-arrow.png" alt="" />
				</div>
			</div>
		</div>
		<div class="page-wrapper">
			<div class="container">
				<div class="service-row wow fadeInDown">
					<div class="service-photo"><img src="../assets/image/s-pic-1.png" alt="" /></div>
					<div class="flex_bd">
						<div class="service-card">
							<div class="service-grid">
								<div class="tit">跨境四方聚合</div>
								<div class="desc">针对国内进口电商行业定制的跨境四方聚合方案，首家在人民银行报备通过。通过功能强大的记账体系，依托于微信/支付宝的基础支付功能和银行基础账户功能，为商户提供APP收单+跨境付款+国内代付+海关推单功能一整套解决方案。</div>
							</div>
							<div class="service-cell">
								<div class="service-cell_hd">产品优势：</div>
								<ul class="service-list">
									<li>
										<span>01</span>为商户提供微信和支付宝APP收单通道
									</li>
									<li>
										<span>02</span>依托底层银行账户能力，为商户提供跨境付款和国内代付功能
									</li>
									<li>
										<span>03</span>同时支持人民币和外汇跨境
									</li>
									<li>
										<span>04</span>通过银行账户管理资金保证安全并提供金融增值服务
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="service-row wow fadeInDown">
					<div class="service-photo"><img src="../assets/image/s-pic-2.png" alt="" /></div>
					<div class="flex_bd">
						<div class="service-card">
							<div class="service-grid">
								<div class="tit">税务核定征收</div>
								<div class="desc">根据国家税务总局公告2019年第36号规定，在指定的跨境电子商户综合试验区内完成相关注册和备案，并通过当地海关进行电子商户出口申报手续，对于采购货物未取得的进项发票，在海外销售回款时能够按照4%的应税所得率进行阳光缴税。</div>
							</div>
							<div class="service-cell">
								<div class="service-cell_hd">产品优势：</div>
								<ul class="service-list">
									<li>
										<span>01</span>能够提供一站式的综试区内的企业注册和备案
									</li>
									<li>
										<span>02</span>提供全线上化的报关功能
									</li>
									<li>
										<span>03</span>依托银行进行合规收汇安全可靠
									</li>
									<li>
										<span>04</span>帮助客户按照应税所得率 4% * 企业所得税25% = 1%的税赋进行缴税
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="service-row wow fadeInDown">
					<div class="service-photo"><img src="../assets/image/s-pic-3.png" alt="" /></div>
					<div class="flex_bd">
						<div class="service-card">
							<div class="service-grid">
								<div class="tit">9610报关退税</div>
								<div class="desc">客户以跨境电商零售9610方式进行海关申报，在海外销售的资金通过银行进行对公账户后，能够合法合规进行申报退税增加企业利润。</div>
							</div>
							<div class="service-cell">
								<div class="service-cell_hd">产品优势：</div>
								<ul class="service-list">
									<li>
										<span>01</span>小包货物能够阳光报关出口并获得报关单
									</li>
									<li>
										<span>02</span>海外销售资金能够阳光申报入境
									</li>
									<li>
										<span>03</span>资金能够合法合规进入对公账户申请退税
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="service-row wow fadeInDown">
					<div class="service-photo"><img src="../assets/image/s-pic-4.png" alt="" /></div>
					<div class="flex_bd">
						<div class="service-card">
							<div class="service-grid">
								<div class="tit">AI智能运营</div>
								<div class="desc">以客户ERP经营数据为依据，结合行业大数据为参考，根据客户具体的销售品类、营销计划量身订制营销方案，在营销方案投产时，能够根据实时效果反馈结合大数据的智能分析，提供营销策略动态调整的决策方案。</div>
							</div>
							<div class="service-cell">
								<div class="service-cell_hd">产品优势：</div>
								<ul class="service-list">
									<li>
										<span>01</span>深度分析客户的行业和品类量身订制营销方案
									</li>
									<li>
										<span>02</span>基于大数据依托智能AI分析动态调整营销策略
									</li>
									<li>
										<span>03</span>能够帮助客户真正打造品类销售标杆
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
		},
		data() {
			return {
				banner:require('../assets/image/serve_banner.jpg')
			};
		},
		methods: {

		},
		mounted() {
			new this.$wow.WOW().init();
		}
	}
</script>
